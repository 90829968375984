// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// In this case I will describe the parameters the mat-palette
// The mat-palette function accepts five parameters, the first being the color palette, 
// the other three being the main shade, the lighter shade, the darker shade(in sequence) 
// and the last being text color. For default the primary color is 500, the lighter is 100 
// and the darker is 700
// Example: $BSPSERPBACK-primary: mat-palette($mat-blue,500,100, 700);
$BSPSERPBACK-primary: mat.define-palette(mat.$blue-palette);
$BSPSERPBACK-accent: mat.define-palette(mat.$light-green-palette);
//$BSPSERPBACK-dark-primary: mat-palette($mat-blue,700);
//$BSPSERPBACK-light-primary: mat-palette($mat-blue,100);
$BSPSERPBACK-primary-text: mat.define-palette(mat.$grey-palette,900);
$BSPSERPBACK-text-icons: mat.define-palette(mat.$grey-palette,A100);
$BSPSERPBACK-secondary-text: mat.define-palette(mat.$grey-palette,600);
$BSPSERPBACK-divider-color: mat.define-palette(mat.$grey-palette,A400);


// The warn palette is optional (defaults to red).
$BSPSERPBACK-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$BSPSERPBACK-theme: mat.define-light-theme((
  color: (
    primary: $BSPSERPBACK-primary,
    accent: $BSPSERPBACK-accent,
    warn: $BSPSERPBACK-warn,
    // dark-primary:$BSPSERPBACK-dark-primary,
    // light-primary: $BSPSERPBACK-light-primary,
    primary-text: $BSPSERPBACK-primary-text,
    secondary-text: $BSPSERPBACK-secondary-text,
    divider-color: $BSPSERPBACK-divider-color,
    text-icons:$BSPSERPBACK-text-icons
  )
));
$custom-theme: mat.define-light-theme($BSPSERPBACK-primary, $BSPSERPBACK-accent, $BSPSERPBACK-warn);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($BSPSERPBACK-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; font-family: Roboto, "Helvetica Neue", tahoma; 
  font-size: 12px;
}

.container-card{
  align-items: center;
}
.float-card {
  margin:5px 8px 8px 0px;
  float: left;
  overflow: hidden;
}
.ancho-60p{
  width: 60%;
}
.ancho-70p{
  width: 70%;
}
.ancho-80p{
  width: 80%;
}
.ancho-90p{
  width: 90%;
}
.ancho-100p{
  width: 100%;
}
.margin-auto{
  margin:auto;
}
.card-table-content{
  max-height: 200px;
  overflow: auto;
}
.mat-card-shadow{
  box-shadow: 0px 3px 1px 1px #68a5d0, 0px 1px 1px 0px #68a5d0, 0px 1px 3px 0px #68a5d0 !important;
}
